import React from 'react'
import './Reports.css'
const HeadingBefore = () => {
  return (
    <div >
        <strong className='personalized-report-head'>All the data you need to Build a successful Business </strong><span className='before-head' >is just a click away</span>
        <br />
        <br />
           
    </div>
  )
}

export default HeadingBefore
