import React from 'react'

const HeadingAfter = () => {
  return (
    <div>
                <h3 className='personalized-report-head'>Your Personalized Report</h3>
    </div>
  )
}

export default HeadingAfter
